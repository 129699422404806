import React from 'react'

const CheckboxMetaField = (props) => {
  const { onChange, value, name, label } = props

  return (
    <div className="form-field">
      <label>
        <input
          type="checkbox"
          checked={value || false}
          onChange={(e) => onChange(!value)}
        />
        { label || name }
      </label>
    </div>
  )
}

export default CheckboxMetaField