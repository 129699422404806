export const withConfirmation = (question) => {
  return new Promise((resolve, reject) => {
    if(confirm(question)) {
      resolve()
    }
  })
}

export const showFlashMessage = (message, options = {}) => {
  const type = options["type"] || "notice"
  const removeAfter = options["removeAfter"] || 0

  const main = document.querySelector("main")
  const flashClasses = [
    "callout",
    "callout--fixed",
    type == "notice" ? "success" : "alert"
  ]

  if(main) {
    const template = document.createElement("template")
    template.innerHTML = `
      <div data-closable class="${flashClasses.join(" ")}" style='margin-bottom: 0'>
        <div>${message}</div>
        <button class="close-button" aria-label="Dismiss alert" type="button" data-close>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    `
    main.appendChild(template.content)

    if(removeAfter && removeAfter > 0) {
      setTimeout(() => {
        for(let callout of Array.from(main.querySelectorAll(".callout"))) {
          main.removeChild(callout)
        }
      }, removeAfter)
    }
  }
}

export const formifyObject = (object, basePrefix = "") => {
  let formified = {}
  let queue = Object.keys(object).map(key => ({value: object[key], key: key, prefix: basePrefix}))

  while(queue.length) {
    const { value, key, prefix } = queue.shift()
    const prefixedKey = prefix + `[${key}]`

    if(value instanceof Array) {
      queue = queue.concat(value.map((arrayValue, arrayIndex) => ({value: arrayValue, key: arrayIndex, prefix: prefixedKey})))
    }else if(value instanceof Object) {
      queue = queue.concat(Object.keys(value).map(objectKey => ({value: value[objectKey], key: objectKey, prefix: prefixedKey})))
    }else{
      if(value !== null && value !== undefined) {
        formified[prefixedKey] = value
      }
    }
  }

  return formified
}