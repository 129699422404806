import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

class GroupErrorIcon extends React.Component {
  render() {
    const error = this.props.error != null ? "Error: " + this.props.error.trim() : '';

    return (error != '') ? (
      <i className="fi-prohibited fi-table-lock text--alert"
        style={{color: '#cc4b37'}}
        data-original-title={error}
        title={error}
      />
    ) : '';
  }
}

class GroupCollectionGroupName extends React.Component {
  render() {
    if (this.props.rock_group_id) {
      return this.content();
    } else {
      return (
        <span style={{fontStyle: "oblique"}}>
          {this.content()}
        </span>
      );
    }
  }

  content() {
    return (
      <div>
        <GroupErrorIcon error={this.props.error} />
        {this.props.finalized == "true" && (
          <i
            className="fi-lock fi-table-lock"
            data-original-title="Finalized Group"
            title="Finalized Group"
          />
        )}
        {this.props.pretty_name}
        <span className="text--caption text--small text--secondary">
          {this.props.admin_name}
        </span>
      </div>
    );
  }
}

class GroupCollectionGroup extends React.Component {
  render() {
    const data = this.props.groups;

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: ({ value }) => (
          <GroupCollectionGroupName
            rock_group_id={value["rock_group_id"]}
            error={value["last_error"]}
            finalized={value["is_finalized"]}
            locked={value["is_locked"]}
            admin_name={value["admin_name"]}
            pretty_name={value["pretty_name"]} />
        ),
        filterMethod: (filter, row) => {
          return String(row["name"]["pretty_name"])
            .concat(String(row["name"]["admin_name"]))
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        },
        sortMethod: (a, b) => {
          if (a.pretty_name === b.pretty_name) {
            return 0;
          }

          return a.pretty_name > b.pretty_name ? 1 : -1;
        },
        width: 200,
      },
      {
        id: "leader",
        Header: "Leaders",
        accessor: "leader",
        filterable: true,
      },
      {
        Header: "Meeting Time",
        accessor: "meeting_time",
        filterable: true,
      },
      {
        Header: "Meeting Day",
        accessor: "day_of_week",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {this.props.days_of_week.map((state) => (
              <option key={state.table.value} value={state.table.value}>
                {state.table.value}
              </option>
            ))}
          </select>
        ),
        width: 120,
      },
      {
        Header: "Invitations",
        Filter: () => (<strong>{data.reduce((m, group) => m + group.pending_invitations_count, 0)}</strong>),
        accessor: "pending_invitations_count",
        filterable: true,
        width: 100,
      },
      {
        Header: "Open Spots",
        Filter: () => (<strong>{data.reduce((m, group) => m + group.open_spots, 0)}</strong>),
        accessor: "open_spots",
        filterable: true,
        width: 120,
      },
      {
        Header: "Capacity",
        Filter: () => (<strong>{data.reduce((m, group) => m + group.capacity, 0)}</strong>),
        accessor: "capacity",
        filterable: true,
        width: 100,
      },
      {
        Header: "Visible",
        accessor: "visible",
        Cell: ({ value }) => (value ? "Yes" : "No"),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id].toString() == filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        ),
        width: 80,
      },
      {
        Header: "Event",
        accessor: "event",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {this.props.events.map((state) => (
              <option key={state.table.value} value={state.table.value}>
                {state.table.value}
              </option>
            ))}
          </select>
        ),
        width: 120,
      },
      {
        Header: "",
        accessor: "links",
        Cell: ({ value }) => (
          <div className="clearfix">
            <div className="float-right">
              <a href={value["show"]} className="action-button edit">
                <i
                  className="fi-magnifying-glass tooltips"
                  data-original-title="Edit Entry"
                  title="Edit Entry"
                />
              </a>
              <a
                href={value["print"]}
                target="_blank"
                className="action-button print"
              >
                <i
                  className="fi-print tooltips"
                  data-original-title="Print Roster"
                  title="Print Roster"
                />
              </a>
            </div>
          </div>
        ),
        filterable: false,
        width: 90,
      },
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFiltered={this.props.defaultFilters}
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
      />
    );
  }
}

GroupCollectionGroup.propTypes = {
  groups: PropTypes.array,
  tags: PropTypes.array,
  days_of_week: PropTypes.array,
  events: PropTypes.array,
  defaultFilters: PropTypes.array,
};

export default GroupCollectionGroup;
