import React from 'react'
import Modal from './Modal'
import { getTypes, getListItemSettingsForType } from './config'

const AddQuestionModal = (props) => {
  const { onChoose, onCancel } = props
  const types = getTypes()

  const handleCancel = () => {
    onCancel()
  }

  const handleChoose = (type) => {
    onChoose(type)
  }

  return (
    <Modal id="add-question-modal">
      <div className="modal__header">
        <strong>Choose a Question Type</strong>
      </div>

      <div className="modal__body">
        <div className="question-editor__question-type-chooser">
          {
            types.map(type => {
              const settings = getListItemSettingsForType(type)
              return (
                <button
                  key={type}
                  type="button"
                  className={`question-editor__question-type-option question-editor__question-type-option--${type}`}
                  title={settings["tooltip"] || ""}
                  onClick={() => handleChoose(type)}
                >
                  <i className={settings.icon} />
                  { settings["label"] || type }
                </button>
              )
            })
          }
        </div>
      </div>

      <div className="modal__footer">
        <button type="button" className="button secondary" onClick={handleCancel}>Cancel</button>
      </div>
    </Modal>
  )
}

export default AddQuestionModal