import React from "react"
import PropTypes from "prop-types"
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'

class TagsList extends React.Component {
  render() {
    const data = this.props.tags

    const columns = [
      {
        id: 'tagname',
        Header: 'Name',
        accessor: 'name',
        filterable: true
      },
      {
        id: 'church',
        Header: 'Church',
        accessor: 'church',
        width: 300,
        Cell: ({ value }) => (value),
        filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) =>
                <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
                  >
          <option value="all">Show All</option>

          { this.props.churches.map((state) => <option key={state.export_name} value={state.export_name}>{state.export_name}</option>) }

                </select>
      },
      {
        Header: '',
        accessor: 'links',
        Cell: ({value}) => (
          <div className="clearfix">
            <div className="float-right">
              <a href={value["edit"]} className='action-button edit'><i className="fi-pencil tooltips" data-original-title="Edit Entry" title="Edit Entry"></i></a>
            </div>
          </div>
        ),
        filterable: false,
        width: 90
      }
    ]

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        defaultPageSize={10}
        filterable
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
        defaultSorted={[
          { id: 'tagname', desc: false }
        ]}
      />
    );
  }
}

TagsList.propTypes = {
  tags: PropTypes.array,
  churches: PropTypes.array
};

export default TagsList
