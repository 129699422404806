import React from "react";
import PropTypes from "prop-types";

let KEY_TAB = 9;
let KEY_SPACE = 32;
let KEY_LEFT = 37;
let KEY_UP = 38;
let KEY_RIGHT = 39;
let KEY_DOWN = 40;

class RadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);

    this.defaultSelectedIndex = this.defaultSelectedIndex.bind(this);
    this.renderRadioButton = this.renderRadioButton.bind(this);
    this.selectIndex = this.selectIndex.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    let defaultSelectedIndex = this.defaultSelectedIndex();

    this.state = {
      selectedIndex: defaultSelectedIndex,
      focusedIndex: defaultSelectedIndex == -1 ? 0 : defaultSelectedIndex,
      focused: false,
    };
  }

  defaultSelectedIndex() {
    if (this.props.selected == null) return -1;

    return this.props.options.findIndex((item) => {
      return item[1] == this.props.selected;
    });
  }

  selectIndex(index) {
    if (index < 0) index += this.props.options.length;

    let gatedIndex = Math.abs(index % this.props.options.length);
    this.setState({ selectedIndex: gatedIndex, focusedIndex: gatedIndex });
  }

  handleKeyDown(e) {
    if (e.keyCode == KEY_LEFT || e.keyCode == KEY_UP) {
      e.preventDefault();
      this.selectIndex(this.state.selectedIndex - 1);
    }

    if (e.keyCode == KEY_RIGHT || e.keyCode == KEY_DOWN) {
      e.preventDefault();
      this.selectIndex(this.state.selectedIndex + 1);
    }

    if (e.keyCode == KEY_TAB) {
      this.setState({ focused: false });
    }

    if (e.keyCode == KEY_SPACE) {
      e.preventDefault();
      this.setState({ selectedIndex: this.state.focusedIndex });
    }
  }

  renderRadioButton(radioButtonOption, index) {
    let isFocused = index == this.state.focusedIndex && this.state.focused;

    var radioOptionClasses = "radio-option cell small-auto";
    if (isFocused) radioOptionClasses += " radio-option--focused";

    return (
      <label
        className={radioOptionClasses}
        tabIndex={isFocused ? 0 : -1}
        key={index}
      >
        <input
          id={this.props.id}
          type="radio"
          name={this.props.name}
          value={radioButtonOption[1]}
          className={`radio--hidden${
            this.props.required === true ? " validation-required" : ""
          }`}
          checked={index == this.state.selectedIndex}
          onChange={(e) => {
            this.selectIndex(index);
          }}
        />
        <span className="radio"></span>
        <span className="radio-label">{radioButtonOption[0]}</span>
      </label>
    );
  }

  render() {
    return (
      <div
        className="radio-button-group"
        tabIndex={this.state.focused ? -1 : 0}
        onKeyDown={this.handleKeyDown}
        onFocus={() => {
          this.setState({ focused: true });
        }}
        onBlur={() => {
          this.setState({ focused: false });
        }}
      >
        <div className="grid-x">
          {this.props.options.map(this.renderRadioButton)}
        </div>
      </div>
    );
  }
}

RadioButtonGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.string,
};

export default RadioButtonGroup;
