import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

class GroupList extends React.Component {
  render() {
    const data = this.props.groups;

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        id: "leader",
        Header: "Leader",
        accessor: "leader",
        width: 165,
        filterable: true,
      },
      {
        Header: "Event Date",
        accessor: "event_date",
        filterable: false,
        width: 175,
      },
      {
        Header: "Meeting Day",
        accessor: "day_of_week",
        width: 120,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </select>
        ),
      },
      {
        Header: "Meeting Time",
        accessor: "meeting_time",
        width: 130,
        filterable: true,
      },
      {
        Header: "Open Spots",
        accessor: "open_spots",
        filterable: false,
        width: 120,
        Cell: (row) => {
          if (row.value != 0) {
            return row.value;
          }
          return (
            <div>
              {row.value} <i className="fi-alert" style={{ color: "red" }}></i>
            </div>
          );
        },
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        filterable: false,
        width: 100,
      },
      {
        Header: "",
        accessor: "action",
        filterable: false,
        width: 130,
        Cell: (row) =>
          row.value["select"] != null && (
            <form action={row.value["select"]} method="POST">
              <input
                type="hidden"
                name="authenticity_token"
                value={this.props.authenticity_token}
              />
              <input
                value="Select Group"
                type="submit"
                className="button_to action-button select-group"
                data-confirm="Moving all people listed above into this group. Are you sure?"
              />
            </form>
          ),
      },
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
      />
    );
  }
}

GroupList.propTypes = {
  groups: PropTypes.array,
};
export default GroupList;
