import React from 'react'

const QuestionListItem = (props) => {
  const {
    question,
    isSelected,
    onSelect,
    onMoveUp,
    onMoveDown,
    onDuplicate,
    onDelete,
    onEdit,
    listDisplay,
    icon,
    editable
  } = props
  const invalid = props.invalid || false
  let outerId = `question-${question.id || question.temporaryId}`
  let outerClass = ["question-editor__question", `question-editor__question--${question.question_type}`]
  if(isSelected) outerClass.push("question-editor__question--selected")
  if(invalid) outerClass.push("question-editor__question--invalid")
  if(question.answer_count > 0) outerClass.push("question-editor__question--has-answers")

  const handleMouseDown = (e) => {
    onSelect(question)
  }

  const handleDuplicate = (e) => {
    onDuplicate(question)
  }

  const handleDelete = (e) => {
    onDelete(question)
  }

  const handleEdit = (e) => {
    e.preventDefault()
    onEdit(question)
  }

  const representation = () => {
    if(editable) {
      return (
        <a href="#" onClick={handleEdit}>{listDisplay(question)}</a>
      )
    }else{
      return listDisplay(question)
    }
  }

  return (
    <div
      id={outerId}
      className={outerClass.join(" ")}
      onMouseDown={handleMouseDown}
      onDoubleClick={handleEdit}
    >
      <div className="question-editor__question-icon"><i className={icon} /></div>
      <div className="question-editor__question-representation">{representation()}</div>

      <div className="question-editor__question-actions">
        <button type="button" onClick={handleDuplicate} title="Duplicate question" className="duplicate-question-button"><i className="fi-page-copy" /></button>
        <button type="button" onClick={handleDelete} title="Delete question" className="delete-question-button"><i className="fi-trash" /></button>
      </div>
    </div>
  )
}

export default QuestionListItem