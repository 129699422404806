import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import { getListItemSettingsForType } from './config'
import { withConfirmation } from '../../utils'

const CopyFromOtherGroupCollectionModal = (props) => {
  const { excludeGroupCollection, onChooseGroupCollection, onCancel } = props
  const [groupCollections, setGroupCollections] = useState([])
  const [groupCollection, setGroupCollection] = useState(undefined)
  const [groupCollectionQuestions, setGroupCollectionQuestions] = useState(undefined)
  const loadingQuestions = groupCollection && !groupCollectionQuestions
  const loadedEmptyQuestions = groupCollection && groupCollectionQuestions && groupCollectionQuestions.length == 0
  const loadedSomeQuestions = groupCollection && groupCollectionQuestions && groupCollectionQuestions.length > 0

  const handleChooseGroupCollection = e => {
    onChooseGroupCollection({
      chosenGroupCollection: groupCollection,
      groupCollectionQuestions
    })
  }

  const handleCancel = e => {
    onCancel()
  }

  const updateGroupCollections = (incomingGroupCollections) => {
    setGroupCollections(incomingGroupCollections.filter(gc => gc.id != excludeGroupCollection.id))
  }

  const setGroupCollectionById = (id) => {
    setGroupCollection(groupCollections.find(gc => gc.id == id))
  }

  useEffect(() => {
    fetch("/admin/group_collections", {headers: {"Accept": "application/json"}})
      .then(response => response.json())
      .then(json => updateGroupCollections(json))
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if(groupCollection) {
      setGroupCollectionQuestions(undefined)

      fetch(`/admin/group_collections/${groupCollection.id}/questions`, {headers: {"Accept": "application/json"}})
        .then(response => response.json())
        .then(json => setGroupCollectionQuestions(json))
        .catch(error => console.log(error))
    }
  }, [groupCollection])

  return (
    <Modal id="copy-questions-modal" flexibleHeight={true}>
      <div className="modal__header">
        <p><strong>Choose a Group Collection</strong></p>

        <select name="group_collection" onChange={(e) => setGroupCollectionById(e.target.value)} style={{margin: 0}}>
          <option key="" />
          {
            groupCollections.map(groupCollection =>
              <option key={groupCollection.id} value={groupCollection.id}>{groupCollection.name}</option>
            )
          }
        </select>
      </div>

      <div className="modal__body" style={{paddingBottom: "1rem"}}>
        {
          loadingQuestions && (
            <div className="callout info">Loading...</div>
          )
        }

        {
          loadedEmptyQuestions && (
            <div className="callout info">This group collection has no questions</div>
          )
        }

        {
          loadedSomeQuestions && (
            <ul className="compact-question-list">
              {
                groupCollectionQuestions.map(question => {
                  const settings = getListItemSettingsForType(question.question_type)

                  return (
                    <li key={question.id} className="compact-question-list__item">
                      <i className={settings.icon} />
                      {question.display_text}
                    </li>
                  )
                })
              }
            </ul>
          )
        }
      </div>

      <div className="modal__footer">
        <button type="button" disabled={!loadedSomeQuestions} className="button primary" onClick={handleChooseGroupCollection}>Copy Questions</button>
        <button type="button" className="button secondary" onClick={handleCancel}>Cancel</button>
      </div>
    </Modal>
  )
}

export default CopyFromOtherGroupCollectionModal