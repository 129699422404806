import React, { useContext, useEffect, useRef, useState } from 'react'

const DragonDropContext = React.createContext()
const DragonDropZoneContext = React.createContext()

export const DragonDrop = (props) => {
  const { groups, onMove, children } = props
  const [selected, setSelected] = useState(undefined)
  const [dragging, setDragging] = useState(undefined)
  const [fromContainer, setFromContainer] = useState(undefined)
  const [dropping, setDropping] = useState(undefined)
  const [toContainer, setToContainer] = useState(undefined)

  const move = (item, fromContainer, toContainer, atPosition = -1) => {
    onMove(item, fromContainer, toContainer, atPosition)
    setDropping(undefined)
    setFromContainer(toContainer)
  }

  const context = {
    groups,
    selected,
    setSelected,
    dragging,
    setDragging,
    fromContainer,
    setFromContainer,
    dropping,
    setDropping,
    toContainer,
    setToContainer,
    move
  }

  return (
    <DragonDropContext.Provider value={context}>
      {typeof children == "function" ? children(groups) : children}
    </DragonDropContext.Provider>
  )
}

export const Dropzone = (props) => {
  const { container, children } = props
  const { groups, dragging, fromContainer, toContainer, setToContainer, move } = useContext(DragonDropContext)
  const findItemIndex = (item) => groups[container].indexOf(item)
  const context = { container, findItemIndex }

  const handleDragEnter = (e) => {
    setToContainer(container)

    if(fromContainer != container) {
      e.preventDefault()
      e.stopPropagation()
      move(dragging, fromContainer, container)
    }
  }

  const handleDrop = (e) => {
    if(dragging) {
      e.preventDefault()
    }
  }

  return (
    <DragonDropZoneContext.Provider value={context}>
      <div
        className="dragon-drop-zone"
        onDragEnterCapture={handleDragEnter}
        onDrop={handleDrop}
      >
        {children}
      </div>
    </DragonDropZoneContext.Provider>
  )
}

export const Draggable = (props) => {
  const { item, children } = props
  const { selected, setSelected, dragging, setDragging, fromContainer, setFromContainer, dropping, setDropping, toContainer, setToContainer, move } = useContext(DragonDropContext)
  const { container, findItemIndex } = useContext(DragonDropZoneContext)
  const [draggable, setDraggable] = useState(false)
  const itemRef = useRef()

  const handleMouseDown = (e) => {
    setSelected(item)
    setDraggable(true)
  }

  const handleDragStart = (e) => {
    setDragging(item)
    setFromContainer(container)
    e.dataTransfer.effectAllowed = "move"
  }

  const handleDragEnd = (e) => {
    setDragging(undefined)
    setDropping(undefined)
    setFromContainer(undefined)
    setDraggable(false)
  }

  const handleDragEnter = (e) => {
    if(dropping || item == dragging || item == dropping) {
      return
    }

    e.stopPropagation()
    setDropping(item)
  }

  const handleDragOver = (e) => {
    if(!dropping || item != dropping) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    const { top, height } = itemRef.current.getBoundingClientRect()
    const { clientY } = e
    const draggingOntoTop = clientY - top < height / 2
    const draggableIndex = findItemIndex(dragging)
    const droppingIndex = findItemIndex(dropping)
    const draggableIsBefore = (draggableIndex != -1) && draggableIndex == droppingIndex - 1
    const draggableIsAfter = (draggableIndex != -1) && draggableIndex == droppingIndex + 1

    if(draggingOntoTop && !draggableIsBefore || draggableIndex == -1) {
      move(dragging, fromContainer, toContainer, droppingIndex)
    }else if(!draggingOntoTop && !draggableIsAfter || draggableIndex == -1) {
      move(dragging, fromContainer, toContainer, droppingIndex + 1)
    }
  }

  const handleDragLeave = (e) => {
    e.stopPropagation()

    if(item == dragging || item == dropping) {
      return
    }

    setDropping(undefined)
  }

  let classes = ["dragon-drop-item"]
  if(selected == item) classes.push("selected")
  if(dropping == item) classes.push("dropping")

  return (
    <div
      className={classes.join(" ")}
      onMouseDown={handleMouseDown}
      onDragStart={handleDragStart}
      onDragEnterCapture={handleDragEnter}
      onDragOverCapture={handleDragOver}
      onDragLeaveCapture={handleDragLeave}
      onDragEnd={handleDragEnd}
      draggable={draggable}
      ref={itemRef}
    >
      {children}
    </div>
  )
}