import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import OptionsMetaField from './OptionsMetaField'
import CheckboxMetaField from './CheckboxMetaField'

export const questionTypes = {
  text: {
    icon: "fi-text-color"
  },
  textarea: {
    icon: "fi-align-left",
    label: "Text Area"
  },
  radio: {
    icon: "fi-record",
    tooltip: "Use for multiple choice questions where only one answer can be selected.",
    metaFields: {
      options: OptionsMetaField
    }
  },
  checkbox: {
    icon: "fi-checkbox",
    requireable: false,
    tooltip: "Use for multiple choice questions where multiple answers can be selected. Also, when no choices are provided this will act a yes or no question.",
    metaFields: {
      options: OptionsMetaField
    }
  },
  dropdown: {
    icon: "fi-list-bullet",
    tooltip: "Use for multiple choice questions where only one answer can be selected. Often used instead of the radio option when there are a large number of options.",
    metaFields: {
      options: OptionsMetaField
    }
  },
  spacer: {
    icon: "fi-minus",
    listDisplay: (question) => <hr />,
    editable: false,
    exportable: false
  }
}

const invalidType = {
  icon: "fi-alert",
  listDisplay: (question) => `Invalid question type: ${question.question_type}`,
  invalid: true,
  exportable: false
}

const LIST_ITEM_DEFAULTS = {
  icon: "fi-alert",
  listDisplay: (question) => question.display_text,
  editable: true,
  invalid: false
}

const EDITOR_DEFAULTS = {
  exportable: true,
  requireable: true,
  metaFields: {}
}

export const getTypes = () => {
  return Object.keys(questionTypes)
}

export const getListItemSettingsForType = (type) => {
  const typeSettings = questionTypes[type] || invalidType
  return {
    ...LIST_ITEM_DEFAULTS,
    ...typeSettings
  }
}

export const getEditorSettingsForType = (type) => {
  const typeSettings = questionTypes[type] || {}
  return {
    ...EDITOR_DEFAULTS,
    ...typeSettings
  }
}

export const makeQuestion = (settings = {}) => {
  return {
    question_type: "text",
    meta: {},
    temporaryId: uuidv4(),
    ...settings
  }
}

export const questionsMatch = (question, check) => {
  return (question.id && (check.id == question.id)) || (!question.id && (check.temporaryId == question.temporaryId))
}

export const transformSubmission = (questions) => {
  return questions.map((question, index) => {
    return {
      ...question,
      position: index,
      meta: JSON.stringify(question.meta)
    }
  })
}