import React from "react";
import Filter from "./Filter";
import AvailabilityFilter from "./AvailabilityFilter";

class SelfServiceGroupFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        demographics: this.props.demographics,
        availability: {
          days: this.props.days
        }
      }
    };
  }

  handleButtonClick = (items, item_id) => {
    let parentItems = this.state.filters[items];
    let itemToUpdate = parentItems.find(item => {
      return item.id === item_id;
    });
    itemToUpdate.selected = !itemToUpdate.selected;
    this.setState({ [items]: parentItems });
  };

  handleAvailabilityButtonClick = (element, slug) => {
    let parentItems = this.state.filters.availability[element];
    let itemToUpdate = parentItems.find(item => {
      return item.slug === slug;
    });
    itemToUpdate.selected = !itemToUpdate.selected;
    this.setState({ [element]: parentItems });
  };

  queryParams = () => {
    let selectedParams = [];

    this.state.filters.demographics.forEach(item => {
      if (item.selected) {
        selectedParams.push(`demographics[]=${item.slug}`);
      }
    });

    this.state.filters.availability.days.forEach(item => {
      if (item.selected) {
        selectedParams.push(`days[]=${item.slug}`);
      }
    });

    if (selectedParams.length > 0) {
      return "?" + selectedParams.join("&");
    }

    return "";
  };

  render() {
    return (
      <div className="flex-medium-up">
        <Filter
          items={this.state.filters.demographics}
          title="About Me"
          spaceClass="demographics"
          subtitle="Choose all that apply to you."
          onClick={item_id => {
            this.handleButtonClick("demographics", item_id);
          }}
        />

        <AvailabilityFilter
          items={this.state.filters.availability}
          title="Availability"
          spaceClass="availability"
          subtitle="What days are you available?"
          onClick={(item_section, item_slug) => {
            this.handleAvailabilityButtonClick(item_section, item_slug);
          }}
        />
        <a
          href={`${this.props.link_url}${this.queryParams()}`}
          className="button small"
        >
          FILTER
        </a>
      </div>
    );
  }
}

export default SelfServiceGroupFilters;
