import React, { useState } from 'react'
import Modal from './Modal'
import { getEditorSettingsForType } from './config'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  display_text: Yup.string().required("Question text is required"),
  export_name: Yup.string().when("$includeInExport", {
    is: true,
    then: Yup.string().required("Export name is required when including in export"),
    otherwise: Yup.string().nullable()
  })
})

const transformErrors = (errors) => {
  return errors.inner.reduce((all, error) => {
    all[error.path] = error.message
    return all
  }, {})
}

const EditQuestionModal = (props) => {
  const { question, onDone, onCancel } = props
  const [values, setValues] = useState(question)
  const [errors, setErrors] = useState({})
  const [includeInExport, setIncludeInExport] = useState(!!question.export_name)
  const questionIsNew = !question.id
  const settings = getEditorSettingsForType(question.question_type)
  const disableWithAnswers = question.answer_count > 0 ? { disabled: true } : {}

  const setFieldValue = (field, value) => {
    setValues({
      ...values,
      [field]: value
    })
  }

  const setMetaFieldValue = (field, value) => {
    setFieldValue("meta", {
      ...values.meta,
      [field]: value
    })
  }

  const toggleIncludingInExport = () => {
    const willIncludeInExport = !includeInExport
    if(!willIncludeInExport) setFieldValue("export_name", "")
    setIncludeInExport(willIncludeInExport)
  }

  const handleDone = (e) => {
    validationSchema.validate(values, {abortEarly: false, context: { includeInExport }})
      .then(() => onDone(values))
      .catch(errors => setErrors(transformErrors(errors)))
  }

  const handleCancel = (e) => {
    onCancel()
  }

  const fieldError = (field) => {
    if(errors[field]) {
      return <span className="error-message">{errors[field]}</span>
    }else{
      return null
    }
  }

  return (
    <Modal id="edit-question-modal">
      <div className="modal__header">
        <strong><i className={settings.icon} /> { questionIsNew ? "New Question" : "Edit Question" }</strong>
      </div>

      <div className="modal__body">        
        <fieldset {...disableWithAnswers}>
          <div className="form-field">
            <label>Question Text</label>
            <input
              type="text"
              name="display_text"
              value={values.display_text || ""}
              onChange={(e) => setFieldValue("display_text", e.target.value)}
            />
            {fieldError("display_text")}
          </div>

          {
            settings.requireable && (
              <div className="form-field">
                <label>
                  <input
                    type="checkbox"
                    name="required"
                    checked={values.required || false}
                    onChange={(e) => setFieldValue("required", !values.required)}
                  />
                  Required
                </label>
              </div>
            )
          }

          {
            Object.keys(settings.metaFields).map(field => {
              const metaFieldSettings = settings.metaFields[field]
              const [MetaFieldComponent, metaProps] = Array.isArray(metaFieldSettings) ? metaFieldSettings
                                                                                       : [metaFieldSettings, {}]

              return (
                <MetaFieldComponent
                  {...metaProps}
                  question={question}
                  name={field}
                  value={values.meta[field]}
                  onChange={value => setMetaFieldValue(field, value)}
                  key={field}
                />
              )
            })
          }
        </fieldset>

        {
          settings.exportable && (
            <React.Fragment>
              <label>
                <input
                  type="checkbox"
                  name="include_in_export"
                  checked={includeInExport}
                  onChange={toggleIncludingInExport}
                />
                Include in export?
              </label>

              {
                includeInExport && (
                  <div className="form-field">
                    <label>Export Name</label>
                    <input
                      type="text"
                      name="export_name"
                      value={values.export_name || ""}
                      onChange={(e) => setFieldValue("export_name", e.target.value)}
                    />
                    {fieldError("export_name")}
                  </div>
                )
              }
            </React.Fragment>
          )
        }
      </div>

      <div className="modal__footer">
        <button type="button" className="button" onClick={handleDone}>Done</button>
        <button type="button" className="button secondary" onClick={handleCancel}>Cancel</button>
      </div>
    </Modal>
  )
}

export default EditQuestionModal