import React from "react";

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  expandDropdown = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
  };

  selectedCount = () => {
    const selectedItems = this.props.items.filter(item => {
      return item.selected === true;
    });

    return selectedItems.length;
  };

  render() {
    return (
      <div className={`filter-item-container ${this.props.spaceClass}`}>
        <div
          className={`filter-dropdown ${
            this.state.expanded ? "filter-expanded" : ""
          }`}
        >
          <h6 className="filter-description" onClick={this.expandDropdown}>
            {this.props.title}{" "}
            {this.selectedCount() > 0 ? `(${this.selectedCount()})` : ""}
            <span className="chevron float-right" />
          </h6>
          <div className="filter-options">
            <h6 className="instructions">{this.props.subtitle}</h6>

            {this.props.items.map(item => (
              <span
                key={item.slug}
                className={`tag ${item.selected ? "selected" : ""}`}
                onClick={() => {
                  this.props.onClick(item.id);
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
