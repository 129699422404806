import React from "react";

class AvailabilityFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  expandDropdown = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
  };

  selectedCount = () => {
    const selectedItemsDays = this.props.items.days.filter(item => {
      return item.selected === true;
    });
    return selectedItemsDays.length;
  };

  render() {
    return (
      <div className={`filter-item-container ${this.props.spaceClass}`}>
        <div
          className={`filter-dropdown ${
            this.state.expanded ? "filter-expanded" : ""
          }`}
        >
          <h6 className="filter-description" onClick={this.expandDropdown}>
            {this.props.title}{" "}
            {this.selectedCount() > 0 ? `(${this.selectedCount()})` : ""}
            <span className="chevron float-right" />
          </h6>
          <div className="filter-options">
            <h6 className="instructions">{this.props.subtitle}</h6>

            <div className="flex">
              {this.props.items.days.map(item => (
                <span
                  key={item.slug}
                  className={`tag round ${item.selected ? "selected" : ""}`}
                  onClick={() => {
                    this.props.onClick("days", item.slug);
                  }}
                >
                  {item.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AvailabilityFilter;
