import React from 'react'

const Modal = (props) => {
  const { id, flexibleHeight, children } = props

  let modalClasses = "modal"
  let modalBackdropClasses = "modal__backdrop"
  let modalContentClasses = "modal__content"

  if(flexibleHeight) {
    modalContentClasses += " modal__content--flexible-height"
  }

  return (
    <div id={id} className={modalClasses}>
      <div className={modalBackdropClasses} />
      <div className={modalContentClasses}>{children}</div>
    </div>
  )
}

export default Modal