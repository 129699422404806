import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

class EventList extends React.Component {
  render() {
    const data = this.props.events;

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        width: 200
      },
      {
        Header: "Date",
        accessor: "start_date",
        filterable: true,
        width: 225
      },
      {
        id: "group_type",
        Header: "Group Type",
        accessor: "group_type",
        width: 150,
        Cell: ({ value }) => value,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>

            {this.props.group_types.map(state => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        )
      },
      {
        id: "church",
        Header: "Church",
        accessor: "church",
        width: 150,
        Cell: ({ value }) => value,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>

            {this.props.churches.map(state => (
              <option key={state.export_name} value={state.export_name}>
                {state.export_name}
              </option>
            ))}
          </select>
        )
      },
      {
        Header: "Groups",
        accessor: "group_count",
        filterable: false
      },
      {
        Header: "People",
        accessor: "person_count",
        filterable: false
      },
      {
        Header: "",
        accessor: "links",
        width: 150,
        Cell: ({ value }) => (
          <div className="clearfix">
            <div className="float-right">
              <a href={value["edit"]} className="action-button green">
                <i
                  className="fi-magnifying-glass tooltips"
                  data-original-title="Manage Event"
                  title="Manage Event"
                />
              </a>
              <a href={value["groups"]} className="action-button green">
                <i
                  className="fi-torsos-all tooltips"
                  data-original-title="Manage Groups"
                  title="Manage Groups"
                />
              </a>
              <a href={value["people"]} className="action-button green">
                <i
                  className="fi-results-demographics tooltips"
                  data-original-title="Manage People"
                  title="Manage People"
                />
              </a>
            </div>
          </div>
        ),
        filterable: false
      }
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
      />
    );
  }
}

EventList.propTypes = {
  events: PropTypes.array,
  churches: PropTypes.array,
  group_types: PropTypes.array
};

export default EventList;
