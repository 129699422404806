import { combineReducers } from 'redux'
import { makeQuestion, questionsMatch } from '../config'

const findQuestionIndex = (questions, question) => {
  return questions.findIndex(check => questionsMatch(question, check))
}

const questions = (state = [], action) => {
  switch(action.type) {
    case "SET_QUESTIONS": {
      return [...action.questions]
    }
    default:
      return state
  }
}

const changes = (state = [], action) => {
  switch(action.type) {
    case "SET_QUESTIONS": {
      return [...action.questions]
    }
    case "SET_QUESTIONS_FROM_OTHER_GROUP_COLLECTION": {
      let updatedQuestions = [
        ...state.filter(question => question.id).map(question => ({...question, "_destroy": true})),
        ...action.questions.map(question => {
          let importedQuestion = {...makeQuestion(), ...question}
          delete importedQuestion["id"]
          delete importedQuestion["group_collection_id"]
          delete importedQuestion["answer_count"]
          return importedQuestion
        })
      ]

      return updatedQuestions
    }
    case "MOVE_QUESTION": {
      const { question, position } = action
      const existingIndex = findQuestionIndex(state, question)
      const updatedQuestions = [...state]
      updatedQuestions.splice(existingIndex, 1)

      const newPosition = (position == -1) ? updatedQuestions.length - 1 : position
      const offset = (existingIndex < newPosition) ? -1 : 0
      updatedQuestions.splice(position + offset, 0, question)
      return updatedQuestions
    }
    case "MOVE_QUESTION_DOWN": {
      const { question } = action
      const index = findQuestionIndex(state, question)
      if(index == state.length - 1) return state
      const other = state[index + 1]
      const updatedQuestions = [...state]
      updatedQuestions[index + 1] = question
      updatedQuestions[index] = other
      return updatedQuestions
    }
    case "MOVE_QUESTION_UP": {
      const { question } = action
      const index = findQuestionIndex(state, question)
      if(index == 0) return state
      const updatedQuestions = [...state]
      const other = updatedQuestions[index - 1]
      updatedQuestions[index - 1] = question
      updatedQuestions[index] = other
      return updatedQuestions
    }
    case "DUPLICATE_QUESTION": {
      const { question } = action
      const index = findQuestionIndex(state, question)
      let clone = {...question}
      delete clone["temporaryId"]
      const duplicate = {
        ...makeQuestion(),
        ...clone,
        display_text: `${question.display_text} (Copy)`,
        id: null
      }
      const updatedQuestions = [...state]
      updatedQuestions.splice(index + 1, 0, duplicate)
      return updatedQuestions
    }
    case "DELETE_QUESTION": {
      const { question } = action
      const index = findQuestionIndex(state, question)
      const updatedQuestions = [...state]

      if(question.id) {
        updatedQuestions[index] = {...question, "_destroy": true}
      }else{
        updatedQuestions.splice(index, 1)
      }
      
      return updatedQuestions
    }
    case "UPDATE_QUESTION": {
      const { question } = action
      const index = findQuestionIndex(state, question)
      const updatedQuestions = [...state]

      if(index == -1) {
        updatedQuestions.push(question)
      }else{
        updatedQuestions.splice(index, 1, question)
      }

      return updatedQuestions
    }
    default:
      return state
  }
}

const dirty = (state = false, action) => {
  switch(action.type) {
    case "SET_QUESTIONS":
      return false
    case "MOVE_QUESTION_DOWN":
    case "MOVE_QUESTION_UP":
    case "DUPLICATE_QUESTION":
    case "DELETE_QUESTION":
    case "UPDATE_QUESTION":
    case "MOVE_QUESTION":
      return true
    default:
      return state
  }
}

const selected = (state = null, action) => {
  switch(action.type) {
    case "SET_SELECTED": {
      return action.question
    }
    case "DELETE_QUESTION": {
      if(questionsMatch(action.question, state)) {
        return null
      }
      return state
    }
    default:
      return state
  }
}

export default combineReducers({
  questions,
  changes,
  dirty,
  selected
})
