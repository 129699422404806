import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { DragonDrop, Dropzone, Draggable } from '../DragonDrop'
import QuestionListItem from './QuestionListItem'
import AddQuestionModal from './AddQuestionModal'
import EditQuestionModal from './EditQuestionModal'
import CopyFromOtherGroupCollectionModal from './CopyFromOtherGroupCollectionModal'
import { getListItemSettingsForType, makeQuestion, questionsMatch, transformSubmission } from './config'
import { withConfirmation, showFlashMessage, formifyObject } from '../../utils'

const QuestionList = (props) => {
  const { groupCollection } = props
  const dispatch = useDispatch()
  const questions = useSelector(state => state.questions)
  const changes = useSelector(state => state.changes)
  const dirty = useSelector(state => state.dirty)
  const selected = useSelector(state => state.selected)
  const [editing, setEditing] = useState(undefined)
  const [adding, setAdding] = useState(false)
  const [showingCopyModal, setShowingCopyModal] = useState(false)
  const editorRef = useRef()
  const deletedQuestions = changes.filter(question => !!question["_destroy"])
  const nonDeletedQuestions = changes.filter(question => !question["_destroy"])
  const formData = formifyObject(transformSubmission(changes), "group_collection[questions_attributes]")

  const addQuestion = () => {
    setAdding(true)
  }

  const handleChooseAdding = (type) => {
    const settings = getListItemSettingsForType(type)
    const question = makeQuestion({ question_type: type })

    setAdding(false)

    if(settings.editable) {
      setEditing(question)
    }else{
      dispatch({type: "UPDATE_QUESTION", question})
    }
  }

  const handleCancelAdding = () => {
    setAdding(false)
  }

  const showCopyModal = () => {
    setShowingCopyModal(true)
  }

  const handleCancelCopying = () => {
    setShowingCopyModal(false)
  }

  const handleCopyFromGroupCollection = ({chosenGroupCollection, groupCollectionQuestions}) => {
    if(confirm(`Are you sure you want to copy the questions from ${chosenGroupCollection.name}? This will delete all current questions on the current group collection.`)) {
      const someQuestionsHaveAnswers = questions.some(question => question.answer_count > 0)

      if(someQuestionsHaveAnswers && !confirm("Are you super sure? Some of these questions have already been answered and the answers will be lost.")) {
        return
      }
    }else{
      return
    }

    setShowingCopyModal(false)
    dispatch({type: "SET_QUESTIONS_FROM_OTHER_GROUP_COLLECTION", questions: groupCollectionQuestions})
  }

  const moveQuestionUp = (question) => {
    dispatch({type: "MOVE_QUESTION_UP", question})
  }

  const moveQuestionDown = (question) => {
    dispatch({type: "MOVE_QUESTION_DOWN", question})
  }

  const duplicateQuestion = (question) => {
    dispatch({type: "DUPLICATE_QUESTION", question})
  }

  const deleteQuestion = (question) => {
    if(question.answer_count > 0) {
      withConfirmation("This question has been answered already. If you delete it the answers will also be deleted. Are you sure you want to delete it?")
        .then(() => dispatch({type: "DELETE_QUESTION", question}))
    }else{
      dispatch({type: "DELETE_QUESTION", question})
    }
  }

  const editQuestion = (question) => {
    setEditing(question)
  }

  const handleDoneEditing = (values) => {
    dispatch({type: "UPDATE_QUESTION", question: values})
    setEditing(undefined)
  }

  const handleCancelEditing = () => {
    setEditing(undefined)
  }

  const checkForDeselect = (e) => {
    let target = e.target
    while(target) {
      if(target.matches && target.matches(".question-editor__question")) {
        return
      }
      target = target.parentNode
    }
    dispatch({type: "SET_SELECTED", question: null})
  }

  const handleKeyDown = (e) => {
    switch(e.key) {
      case "Backspace": {
        if(selected) {
          e.preventDefault()
          deleteQuestion(selected)
        }
        break;
      }
      case "d": {
        if(e.metaKey) {
          e.preventDefault()
          duplicateQuestion(selected)
        }
        break;
      }
      case "ArrowUp": {
        if(selected) {
          e.preventDefault()
          moveQuestionUp(selected)
        }
        break;
      }
      case "ArrowDown": {
        if(selected) {
          e.preventDefault()
          moveQuestionDown(selected)
        }
        break;
      }
    }
  }

  const handleMove = (item, fromContainer, toContainer, atPosition) => {
    dispatch({type: "MOVE_QUESTION", question: item, position: atPosition})
  }

  useEffect(() => {
    document.addEventListener("click", checkForDeselect)
    return () => { document.removeEventListener("click", checkForDeselect)}
  }, [editorRef.current])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => { window.removeEventListener("keydown", handleKeyDown) }
  }, [editorRef.current, nonDeletedQuestions, selected, dirty])

  return (
    <div className="question-editor" ref={editorRef}>
      <div className="question-editor__header">
        <div className="question-editor__actions">
          <button
            type="button"
            className="button small secondary question-editor__action"
            onClick={showCopyModal}
          >Copy From Other Collection</button>
        </div>
      </div>

      <DragonDrop groups={[nonDeletedQuestions]} onMove={handleMove}>
        {
          (groups) => groups.map((group, groupIndex) => (
            <Dropzone key={groupIndex} container={groupIndex}>
              {
                group.map((question, index) => {
                  const listItemSettings = getListItemSettingsForType(question.question_type)
                  const isSelected = selected && questionsMatch(question, selected)

                  return (
                    <Draggable key={question.id || question.temporaryId} item={question}>
                      <QuestionListItem
                        question={question}
                        isSelected={isSelected}
                        onSelect={(question) => dispatch({type: "SET_SELECTED", question})}
                        onMoveUp={moveQuestionUp}
                        onMoveDown={moveQuestionDown}
                        onDuplicate={duplicateQuestion}
                        onDelete={deleteQuestion}
                        onEdit={editQuestion}
                        {...listItemSettings}
                      />
                    </Draggable>
                  )
                })
              }
            </Dropzone>
          ))
        }
      </DragonDrop>

      {
        adding && (
          <AddQuestionModal
            onChoose={handleChooseAdding}
            onCancel={handleCancelAdding}
          />
        )
      }

      {
        editing && (
          <EditQuestionModal
            question={editing}
            onDone={handleDoneEditing}
            onCancel={handleCancelEditing}
          />
        )
      }

      {
        showingCopyModal && (
          <CopyFromOtherGroupCollectionModal
            excludeGroupCollection={groupCollection}
            onChooseGroupCollection={handleCopyFromGroupCollection}
            onCancel={handleCancelCopying}
          />
        )
      }

      <button type="button" className="question-editor__add-question-button" onClick={addQuestion}>
        <span><i className="fi-plus" /></span>
      </button>

      {
        Object.keys(formData).map(key => {
          const value = formData[key]
          return (
            <input type="hidden" name={key} value={value} key={key} readOnly />
          )
        })
      }
    </div>
  )
}

export default QuestionList
