import React from 'react'
import { DragonDrop, Dropzone, Draggable } from '../DragonDrop'

const Example = (props) => {
  let groups = [
    [
      {title: "Item 1"},
      {title: "Item 2"},
      {title: "Item 3"}
    ],
    [
      {title: "Item 4"},
      {title: "Item 5"},
      {title: "Item 6"}
    ],
    [
      {title: "Item 7"},
      {title: "Item 8"},
      {title: "Item 9"}
    ]
  ]

  const move = (item, fromContainer, toContainer, atPosition = -1) => {
    const updatedFromContainer = groups[fromContainer]
    const existingIndex = updatedFromContainer.indexOf(item)
    updatedFromContainer.splice(existingIndex, 1)
    groups[fromContainer] = updatedFromContainer

    const updatedToContainer = groups[toContainer]
    const position = (fromContainer != toContainer && atPosition == -1) ? updatedToContainer.length - 1 : atPosition
    const offset = (fromContainer == toContainer && existingIndex < position) ? -1 : 0
    updatedToContainer.splice(position + offset, 0, item)
    groups[toContainer] = updatedToContainer
  }

  return (
    <div className="dragon-drop-example">
      <div className="dragon-drop-row">
        <DragonDrop groups={groups} onMove={move}>
          {
            (groups) => groups.map((group, index) => (
              <Dropzone key={index} container={index}>
                {
                  group.map(item => (
                    <Draggable key={item.title} item={item}>
                      {item.title}
                    </Draggable>
                  ))
                }
              </Dropzone>
            ))
          }
        </DragonDrop>
      </div>
    </div>
  )
}

export default Example