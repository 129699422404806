import React, { useState, useEffect, useRef } from 'react'
import { DragonDrop, Dropzone, Draggable } from '../DragonDrop'
import { v4 as uuidv4 } from 'uuid'

const OptionsMetaField = (props) => {
  const { question, onChange, value, name } = props
  const options = value || []
  const [addingNewOption, setAddingNewOption] = useState(false)
  const metaFieldRef = useRef()

  const addOption = () => {
    onChange([...options, {
      id: uuidv4(),
      display: "New Option"
    }])
    setAddingNewOption(true)
  }

  const removeOption = (index) => {
    let updatedOptions = [...options]
    updatedOptions.splice(index, 1)
    onChange(updatedOptions)
  }

  const moveOption = (option, fromContainer, toContainer, atPosition) => {
    const existingIndex = options.indexOf(option)
    const updatedOptions = [...options]
    updatedOptions.splice(existingIndex, 1)

    const newPosition = (atPosition == -1) ? options.length - 1 : atPosition
    const offset = (existingIndex < newPosition) ? -1 : 0
    updatedOptions.splice(newPosition + offset, 0, option)
    onChange(updatedOptions)
  }

  const updateOption = (index, value) => {
    const option = options[index]
    let updatedOptions = [...options]
    updatedOptions[index] = {
      ...option,
      display: value
    }
    onChange(updatedOptions)
  }

  const checkForTabPress = (e) => {
    if(e.key == "Tab") {
      e.preventDefault()
      addOption()
    }
  }

  useEffect(() => {
    if(addingNewOption) {
      const lastInput = metaFieldRef.current.querySelector(".dragon-drop-item:last-of-type input")
      lastInput && (lastInput.focus() || lastInput.select())
      setAddingNewOption(false)
    }
  }, [options, addingNewOption])

  return (
    <div className="options-meta-field" ref={metaFieldRef}>
      <strong>Options</strong>

      <DragonDrop groups={[options]} onMove={moveOption}>
        {groups => (
          groups.map((group, groupIndex) => (
            <Dropzone key={groupIndex} container={groupIndex}>
              {group.map((option, index) => (
                <Draggable key={option.id} item={option}>
                  <div className="options-meta-field__option">
                    <i className="fi-list" />
                    <input
                      type="text"
                      value={option.display}
                      onChange={(e) => updateOption(index, e.target.value)}
                      onKeyDown={(e) => (index == options.length - 1) && checkForTabPress(e)}
                    />
                    <button type="button" onClick={() => removeOption(index)}><i className="fi-x" /></button>
                  </div>
                </Draggable>
              ))}
            </Dropzone>
          ))
        )}
      </DragonDrop>

      {
        !question.answer_count && (
          <button type="button" className="question-editor__add-question-button" onClick={addOption}>
            <span><i className="fi-plus" /></span>
          </button>
        )
      }
    </div>
  )
}

export default OptionsMetaField