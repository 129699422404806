import React from "react";
import Filter from "./Filter";
import AvailabilityFilter from "./AvailabilityFilter";

class GroupFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        churches: this.props.churches,
        locations: this.props.locations,
        demographics: this.props.demographics,
        group_types: this.props.group_types,
        availability: {
          days: this.props.days
        }
      },
      full_groups: this.props.full_groups
    };
  }

  handleButtonClick = (items, item_id) => {
    let parentItems = this.state.filters[items];
    let itemToUpdate = parentItems.find(item => {
      return item.id === item_id;
    });
    itemToUpdate.selected = !itemToUpdate.selected;
    this.setState({ [items]: parentItems });
  };

  handleAvailabilityButtonClick = (element, slug) => {
    let parentItems = this.state.filters.availability[element];
    let itemToUpdate = parentItems.find(item => {
      return item.slug === slug;
    });
    itemToUpdate.selected = !itemToUpdate.selected;
    this.setState({ [element]: parentItems });
  };

  handleFullGroupsClick = (element) => {
    this.setState({ full_groups: $(element.target).is(':checked') }, () => {
      $('a.button')[0].click();
    });
  };

  queryParams = () => {
    let selectedParams = [];

    this.state.filters.churches.forEach(item => {
      if (item.selected) {
        selectedParams.push(`churches[]=${item.slug}`);
      }
    });

    this.state.filters.locations.forEach(item => {
      if (item.selected) {
        selectedParams.push(`locations[]=${item.name}`);
      }
    });

    this.state.filters.demographics.forEach(item => {
      if (item.selected) {
        selectedParams.push(`demographics[]=${item.slug}`);
      }
    });

    this.state.filters.group_types.forEach(item => {
      if (item.selected) {
        selectedParams.push(`group_types[]=${item.slug}`);
      }
    });

    this.state.filters.availability.days.forEach(item => {
      if (item.selected) {
        selectedParams.push(`days[]=${item.slug}`);
      }
    });

    if (this.state.full_groups) {
      selectedParams.push('full_groups=true');
    }

    if (selectedParams.length > 0) {
      return "?" + selectedParams.join("&");
    }

    return "";
  };

  render() {
    return (
      <div>
        <div className="flex-medium-up">
          <Filter
            items={this.state.filters.churches}
            title="Church"
            spaceClass="church"
            subtitle="Select each campus whose group options you would like to view."
            onClick={item_id => {
              this.handleButtonClick("churches", item_id);
            }}
          />

          <Filter
            items = {this.state.filters.locations}
            title = "Location"
            spaceClass = "location"
            subtitle = "Select each city whose group options you would like to view."
            onClick = { item_id => { this.handleButtonClick('locations', item_id); }}
          />

          <Filter
            items={this.state.filters.demographics}
            title="About Me"
            spaceClass="demographics"
            subtitle="Choose all that apply to you."
            onClick={item_id => {
              this.handleButtonClick("demographics", item_id);
            }}
          />

          <Filter
            items={this.state.filters.group_types}
            title="Group Type"
            spaceClass="group_types"
            subtitle="Choose all that interest you."
            onClick={item_id => {
              this.handleButtonClick("group_types", item_id);
            }}
          />

          <AvailabilityFilter
            items={this.state.filters.availability}
            title="Availability"
            spaceClass="availability"
            subtitle="What days are you available?"
            onClick={(item_section, item_slug) => {
              this.handleAvailabilityButtonClick(item_section, item_slug);
            }}
          />

          <a href={`${this.props.link_url}${this.queryParams()}`} className="button small">
            FILTER
          </a>
        </div>

        <div className="flex" style={{marginTop: '0.75rem', float: 'right'}}>
          <input
            id="full-groups"
            type="checkbox"
            onChange={e => {this.handleFullGroupsClick(e);}}
            checked={JSON.parse(this.state.full_groups)}
          />
          <label className="full-groups" htmlFor="full-groups">
            Show full groups
          </label>
        </div>
      </div>
    );
  }
}

export default GroupFilters;
