import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

const GroupCollectionInvitationList = ({ invitations }) => {
  const columns = [
    {
      Header: "Email",
      accessor: "email",
      filterable: true,
    },
    {
      Header: "Group Leader?",
      accessor: "group_leader",
      filterable: true,
      Cell: ({ value }) => (value ? "Yes" : "No"),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id].toString() === filter.value;
      },

      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      ),
    },
    {
      Header: "Status",
      accessor: "completed",
      Cell: ({ value }) => (value ? "Complete" : "Pending"),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id].toString() === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="false">Pending</option>
          <option value="true">Complete</option>
        </select>
      ),
    },
    {
      Header: "Spots",
      accessor: "spot_count",
      filterable: false,
    },
    {
      Header: "Group",
      accessor: "group",
      Cell: ({ value }) => <a href={value.link}>{value.name}</a>,
      filterable: true,
      filterMethod: (filter, row) => {
        return String(row["group"]["name"])
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      sortMethod: (a, b) => {
        if (a.name === b.name) {
          return 0;
        }

        return a.name > b.name ? 1 : -1;
      },
    },
  ];

  return (
    <ReactTable
      data={invitations}
      columns={columns}
      className="-striped -highlight"
      filterable
      defaultFilterMethod={(filter, row) =>
        String(row[filter.id])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
      }
    />
  );
};

GroupCollectionInvitationList.propTypes = {
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      group_leader: PropTypes.bool.isRequired,
      spot_count: PropTypes.number.isRequired,
      group: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
export default GroupCollectionInvitationList;
