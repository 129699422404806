import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

class MatchRequestList extends React.Component {
  sendRetry = (match_request_id) => {
    $.ajax({
      url: this.props.retry_url,
      type: "POST",
      data: {
        id: match_request_id,
      },
      success: function (data) {
        alert(data.message);
      },
    });
  };

  render() {
    const data = this.props.match_requests;

    const columns = [
      {
        Header: "Person",
        accessor: "name",
        filterable: true,
        width: 300,
      },
      {
        Header: "People Service ID",
        accessor: "people_service_id",
        filterable: true,
        width: 200,
      },
      {
        Header: "Rock Person Alias ID",
        accessor: "rock_person_alias_id",
        filterable: true,
        width: 200,
      },
      {
        Header: "P.S. Response Status",
        accessor: "response_status",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            {this.props.response_statuses.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: "Published to Rock?",
        accessor: "published",
        width: 200,
        Cell: ({ value }) => value,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          return row[filter.id] === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        ),
      },
      {
        Header: "",
        accessor: "links",
        width: 90,
        Cell: ({ value }) => (
          <div className="clearfix">
            <div className="float-right">
              {value["show"] != null && (
                <a href={value["show"]} className="action-button green">
                  <i
                    className="fi-magnifying-glass tooltips"
                    data-original-title="Person Details"
                    title="Person Details"
                  />
                </a>
              )}
              {value["retry_id"] != null && (
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    this.sendRetry(value["retry_id"]);
                  }}
                  className="action-button red"
                >
                  <i
                    className="fi-refresh tooltips"
                    data-original-title="Retry Upload"
                    title="Retry Upload"
                  />
                </a>
              )}
              {value["retry_id"] == null && (
                <div href={value["retry"]} className="action-button disabled">
                  <i
                    className="fi-refresh tooltips"
                    data-original-title="Successful Upload"
                    title="Successful Upload"
                  />
                </div>
              )}
            </div>
          </div>
        ),
        filterable: false,
      },
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
      />
    );
  }
}

MatchRequestList.propTypes = {
  response_statuses: PropTypes.array,
  retry_url: PropTypes.string,
};

export default MatchRequestList;
