import React from 'react'
import { Provider } from 'react-redux'
import QuestionList from './QuestionList'
import store from './store'
import { v4 as uuidv4 } from 'uuid'

const QuestionEditor = (props) => {
  const { questions, group_collection } = props

  const questionsWithTemporaryIds = questions.map(question => {
    if(!question.id) {
      question.temporaryId = uuidv4()
    }
    
    return question
  })

  store.dispatch({ type: "SET_QUESTIONS", questions: questionsWithTemporaryIds })

  return (
    <Provider store={store}>
      <QuestionList groupCollection={group_collection} />
    </Provider>
  )
}

export default QuestionEditor
